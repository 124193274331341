.header-container {
  height: 80px;
  background-color: #3effc0;
  box-shadow: 0px 4px 4px -4px gray;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
.left-menu-container {
  position: absolute !important;
  top: 100px !important;
  right: 100px !important;
}
.left-menu-item {
  color: blue !important;
  font-size: 15px !important;
  border: none !important;
  outline: none !important;
  background: none !important;
  border-radius: 0 !important;
}
