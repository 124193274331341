.line {
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px #000;
}
.lines {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  border-bottom: 1px #000;
}
