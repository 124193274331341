.error-trends {
  min-height: 100vh;
  width: 100%;
  padding: 10px;
  margin: 5px;
}
.left-buttons {
  right: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.edit-columns {
  border: 1px solid #035c67;
  background-color: white;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-right: 20px;
  left: 10px;
  color: #035c67;
  width: 150px;
  white-space: nowrap;
}
.edit-columns-trends {
  border: 1px solid #035c67;
  background-color: white;
  padding: 5px 5px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-right: -100px;
  left: 10px;
  color: #035c67;
  width: 150px;
  white-space: nowrap;
}
.results-buttons {
  display: flex;
  justify-content: space-between;
}
.mtd-list {
  border: 1px solid #035c67;
  background-color: white;
  padding: 5px 5px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-right: 700px;
  left: 12.5rem;
  color: #035c67;
  width: 55px;
  position: absolute;
}
.mtd-lists {
  border: 1px solid #035c67;
  background-color: white;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-right: 450px;
  left: 12.5rem;
  color: #035c67;
  width: 55px;
  position: absolute;
}

.download-results {
  color: #035c67;
  font-size: 15px;
  border: none;
  outline: none;
  background: none;
  border-radius: 0;
  position: absolute;
}
.download-results p {
  font-size: 15px;
  margin: 0;
  color: #035c67;
}
.assign-to-me {
  color: #035c67;
  font-size: 15;
  border: none;
  outline: none;
  background: none;
  border-radius: 0;
}
.assign-to-me p {
  font-size: 15px;
  margin: 0;
  color: #035c67;
  border: none;
}

.arrow-icon {
  color: #035c67;
  background: none;
  border-radius: 0;
}

.columns {
  position: absolute;
  top: 180px;
  left: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 999;
  margin: 35px;
}

.column-names {
  margin-right: 15px;
  appearance: none;
  background-color: #035c67;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  cursor: pointer;
  color: #035c67;
  top: 12px;
  margin-top: 10px;
}
.column-margin {
  margin-top: 10px;
}
.checkbox-pointer {
  cursor: pointer;
}
.edit-columns-buttons {
  margin-top: 10px;
}
.reset-button {
  margin-right: 10px;
  border: none;
  color: blue;
  background-color: white;
  cursor: pointer;
}
.apply-button {
  margin-right: 10px;
  background-color: #035c67;
  color: white;
  border: none;
  cursor: pointer;
}
.complete-content {
  font-family: Arial, Helvetica, sans-serif;
  padding: 6px;
  margin: 5px;
  text-align: justify;
  line-height: 1.5;
  margin: 5px;
  font-size: 12px;
}
.view-title {
  color: #035c67;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.view-list-item {
  font-size: 14px;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
}
.list-item {
  display: block;
  border-radius: 1px;
  width: 100%;
  text-align: left;
  padding-left: 0;
  font-size: 12px;
  line-height: 1.5;
  font-family: Arial, Helvetica, sans-serif;
  transition: background-color 0.3s ease;
}
.list-item-hovered {
  background-color: #e0f2f1;
}
.no-data {
  font-size: 20px;
  align-items: center;
  justify-content: center;
}
.widget-anchor:hover {
  background-color: #f0f0f0; 
}

.error-container {
  position: relative;
  white-space: normal; 
  max-width: .375rem; 
  
}

.error-container:hover .full-text {
  display: block; 
  position: absolute;
  background-color: #fff; 
  border: 0.0625rem solid #ccc; 
  padding: 0.3125rem; 
  z-index: 10; 
  white-space: normal; 
  left: 0; 
  top: 100%; 
  width: auto; 
  max-width: 20rem; 
  box-shadow: 0 0.125rem 0.3125rem rgba(0, 0, 0, 0.2); 
}

.full-text {
  display: none; 
}







