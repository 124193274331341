.subscriber-container {
  width: 100%;
  position: relative;
  top: -8px;
  margin-left: 25px;
}
.pd-20 {
  padding: 20px;
  border: none;
}
.user-info-label {
  font-weight: bold;
  color: #000000;
  margin-bottom: 4px;
}
.container .user-title-left {
  margin-left: -8px;
  transform: translateY(-8px);
  font-weight: bold;
  border: none;
}
.grid-itemm {
  padding: 40px;
}

.subscriber-info-box {
  width: 100%;
  padding: 30px 87px !important;
  padding-left: 20px;
  margin-left: 25px;
}
