.ag-theme-alpine .ag-cell {
  padding: 10px;
}

.ag-cell {
  display: flex;
  align-items: center;
  
}

.add-tpa-modal-input {
  display: flex;
  flex-direction: row;
}
