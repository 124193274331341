.file-tracking-error .file-tracking-grid-status-text {
  background-color: #f1cccc;
  color: #ba0000;
  font-weight: bold;
}

.file-tracking-working .file-tracking-grid-status-text {
  background-color: #ccdcff;
  color: blue;
  font-weight: bold;
}

.file-tracking-success .file-tracking-grid-status-text {
  background-color: #dae6d1;
  color: #3a6914;
  font-weight: bold;
}

.file-tracking .ag-header-cell-label,
.file-tracking .ag-cell-valuel {
  justify-content: left;
}
.file-tracking-grid {
  height: 750px;
  width: 100%;
  padding: 40px;
  margin-top: -21px;
}

.file-search-title {
  font-size: 22px !important;
  font-weight: bold !important;
  margin-top: -10px !important;
}
.file-details-grid {
  height: 750px;
  width: 100%;
  padding: 40px;
  margin-top: -45px;
}

.file-details-download {
  color: blue !important;
  margin-top: 20px !important;
  font-size: 15px !important;
  border: none !important;
  outline: none !important;
  background: none !important;
  border-radius: 0 !important;
}

.file-details-search-result {
  font-size: 10px;
  color: #a0a0a0;
  margin-top: 38px;
  margin-left: 45px;
}
.file-tracking-search-result {
  font-size: 10px;
  color: #a0a0a0;
  margin-left: 45px;
}

.date-picker-container {
  margin-left: 40px !important;
}

.download-container {
  margin-top: 35px !important;
}
