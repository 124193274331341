.footer-container {
  padding: 20px;
  background-color: #333;
  color: white;
  height: fit-content;
}

.footer-divider {
  height: 2px;
  background-color: #fff;
  width: 90%;
  margin: 20px auto;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  font-size: 10px;
}

.footer-links {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  flex: 1;
  font-size: 10px;
}

.footer-links a {
  text-decoration: none;
  color: #fff;
  margin: 0 15px;
}

.footer-links a:hover {
  text-decoration: underline;
}
/* .footer-size {
  height: 150; 
  background-color: "#035c67";
  align-items: 'flex-end';
} */
