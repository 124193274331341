.Basicsearchform {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  padding: 0px 8px;
  font-size: 14px;
}
.basic-form-group-wrp {
  padding: 2px 0px;
}
.widget-title {
  font-size: 24px;
  font-weight: bold;
}
.widget-subtitle {
  margin-bottom: 20px;
}
.Basicsearchform input {
  display: block;
  margin: 4px 0px;
  padding: 8px;
  width: 20%;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  width: 80%;
}
.form-group-date {
  font-style: italic;
  font-family: Arial, Helvetica, sans-serif;
}
.title {
  font-style: normal;
}
.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  right: 10px;
  gap: 10px;
  padding: 10px;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 5px;
}
.button-group button {
  padding: 10px 20px;
  cursor: pointer;
  border: none !important;
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 5%;
}
.button-group-widget {
  display: flex;
  margin-left: 80%;
  margin-top: 5px;
  right: 10px;
  gap: 10px;
  padding: 10px;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 8px;
}
.button-group-widget button {
  padding: 10px;
  cursor: pointer;
  border: none !important;
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 8px;
}
button:focus {
  outline: 2px;
}
.greentext {
  color: #035c67;
  background-color: transparent;
}
.greenfilled {
  background-color: #035c67;
  color: white;
}
.modal {
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)';
  box-shadow: 24;
  padding: 4;
  max-width: 400px;
  min-width: 400px;
  box-sizing: border-box;
  overflow-y: hidden;
}
.search-form {
  display: flex;
  grid-template-columns: 1fr 1fr;
  gap: 0px 40px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 20px;
  max-width: 570px;
  margin: auto;
  background-color: #fff;
  max-height: 90vh;
  overflow: auto;
  flex-direction: column;
  top: 20%;
  font-family: Arial, Helvetica, sans-serif;
}
.first-group {
  display: grid;
  justify-content: space-between;
  border-radius: 5px;
  grid-template-columns: 1fr 1fr;
  gap: 0px 20px;
  font-family: Arial, Helvetica, sans-serif;
}
.second-group {
  display: grid;
  flex-direction: row;
  grid-template-columns: 1fr 1fr;
  gap: 0px 20px;
  margin-top: 5px;
  font-family: Arial, Helvetica, sans-serif;
}
.form-group input::placeholder {
  display: flex;
  flex-direction: column;
  font-style: italic;
}
.form-group {
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  padding-bottom: 10px;
}
.form-group input {
  width: 100%;
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  border-radius: 8px;
}
.line {
  border: 2px solid rgba(0, 0, 0, 0.534);
}
.button-group-popup {
  padding: 30px;
  cursor: pointer;
  gap: 10px;
  border: none !important;
  outline: none !important;
}
.errmsg {
  position: fixed;
  top: 20%;
  right: 50px;
  transform: translate(-100%, -100%);
  background-color: #035c67;
  color: white;
  padding: 3px 2px;
  border-radius: 1px;
  text-overflow: ellipsis;
  white-space: nowrap;
  animation:
    slideIn 0.5s forwards,
    slideOut 0.5s 2.5s forwards;
}

.pfserrmsg {
  position: absolute;
  top: 50%;
  right: 1px;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 100%;
  max-width: 570px;
  background-color: #035c67;
  color: white;
  padding: 3px 2px;
  border-radius: 1px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes slideIn {
  from {
    transform: translate(-50%, -50%);
  }
  to {
    transform: translate(0, 50%);
  }
}

@keyframes slideOut {
  from {
    transform: translate(0, -50%);
  }
  to {
    transform: translate(100%, -50%);
  }
}
.excel {
  border: none;
  color: blue;
  cursor: pointer;
}
.ag-theme-alpine {
  font-family: Arial, Helvetica, sans-serif;
}
.searchtitle {
  height: 30px;
  display: flex;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 30px;
  font-size: 22px;
  font-weight: bold;
  margin-top: -10px;
}
.titlewithhelp {
  display: flex;
  align-items: center;
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
}
.form-group-date {
  font-style: italic;
}
.placeholder {
  display: block;
  margin-top: 1px;
}
.adv-search-title {
  color: #035c67;
}
.adv-help-icon {
  cursor: pointer;
  color: #035c67;
}
