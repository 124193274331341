.draggable-container-grid-dragging {
  background-color: #000;
}
.grid-edit-btn {
  border: none;
  outline: none !important;
  background: none;
  border-radius: 0;
  cursor: pointer;
  color: #035c67 !important;
  margin-right: 5%;
  text-transform: none !important;
}
.draggable-container-grid {
  min-height: 100px;
  margin-top: 13px;
}
.dashboard-left-menu {
  /* margin-top: 20px; */
  width: 100%;
}
.dashboard-sub-header-container {
  /* margin-top: 10px !important; */
  padding: 0px 50px 50px 50px;
}
.widget-title {
  display: flex;
  align-items: center;
}
.title-border {
  margin-top: 10px;
  border-bottom: 1px solid #919089;
}
.dashboard-title {
  padding-left: 0px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 28px;
}
.dashboard-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
}
.active-widget-area {
  padding: 23px 60px 60px 43px;
  margin-top: -80px !important;
  min-height: 500px;
  height: auto;
}
.dashboard-icons {
  color: #035c67;
  font-size: small;
}
.widgeterr {
  width: 100px;
}
.inactive-widget-area {
  margin-top: 50px;
  padding: 60px;
  background-color: #eaf4f6;
}
