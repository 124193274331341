.client-info-section {
  background-color: #f4f8ff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.client-info-section h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 15px;
}
.employer-group-info-section {
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.employer-group-info-section .user-information-container {
  flex: 1 1 45%;
  margin: 0;
  padding: 5px;
  box-sizing: border-box;
}
.subscriber-info-section {
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.subscriber-info-section .user-information-container {
  flex: 1 1 45%;
  margin: 0;
  padding: 5px;
  box-sizing: border-box;
}
.user-information-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 50px;
  padding: 5px;
}
.user-title-left {
  margin-left: -1000px;
}
.container {
  padding-left: 50px;
}
.UserInformation {
  border: none;
  background-color: transparent;
}
.bold-title {
  font-weight: bold;
}
.form-fields {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: repeat(2, 1fr);
  gap: 55px;
  width: 100%;
  margin: 0 auto;
  padding: 30px 87px;
  border: 0.5px solid black;
  background-color: transparent;
  margin-left: 106px;
}

.form-fields select,
.form-fields input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}
.form-group label {
  margin-bottom: 15px;
  font-weight: bold;
}
.UserInformation {
  border: none;
  background-color: transparent;
}
.bold-title {
  font-weight: bold;
}
.form-fields {
  display: grid;
  grid-template-columns: 1fr;
  gap: 55px;
  width: 100%;
  margin: 0 auto;
  padding: 30px 87px;
  border: 0.5px solid black;
  background-color: transparent;
}

.form-fields select,
.form-fields input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}
.form-group label {
  margin-bottom: 15px;
  font-weight: bold;
}

.product-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 55px;
  width: 100%;
}

.view-products {
  margin: 0 auto;
}

.breakline-address {
  white-space: pre-line;
  line-height: 20px;
}
