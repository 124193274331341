.rejections {
  text-decoration: none;
  background-color: #a18d54;
  color: black;
  border-radius: 4px;
  padding: 4px, 8px;
  cursor:default;
}
.frontend-errors {
  text-decoration: none;
  background-color: #1976d2;
  color: black;
  border-radius: 4px;
  padding: 4px, 8px;
  cursor:default;
}
.unassigned-backend-errors {
  text-decoration: none;
  background-color: #f08080;
  color: black;
  border-radius: 4px;
  padding: 4px, 8px;
  cursor:default;
}
.assigned-backend-errors {
  text-decoration: none;
  background-color: #008000;
  color: black;
  border-radius: 4px;
  padding: 4px, 8px;
  cursor:default;
}
.completed-errors {
  text-decoration: none;
  background-color: #d3d3d3;
  color: black;
  border-radius: 4px;
  padding: 4px, 8px;
  cursor:default;
}
