* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.container {
  padding: 30px 87px !important;
}
.Mui-expanded {
  border: none !important;
}
.MuiPaper-root {
  box-shadow: none !important;
}
.user-info-content {
  border: 1px solid #808080 !important;
}
.user-info-content-selected {
  border: 1px solid #000 !important;
}
.user-display-accordian svg {
  color: blue !important;
}
.pb-35 {
  padding-bottom: 35px;
}
.p-10 {
  padding: 10px;
}
.dependent-list {
  border-bottom: 1px solid #808080;
  border-left: 1px solid #808080;
  border-right: 1px solid #808080;
}
.cursor-pointer {
  cursor: pointer;
}
.iconContainer {
  display: flex;
  align-items: center;
  padding: 4px;
}

.iconContainer:hover {
  background-color: #035c67;
  border-radius: 0%;
  padding: 6px;
}
.icon:hover {
  background-color: #035c67;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}
.header-menu li:hover {
  background-color: #035c67;
  color: #fff;
}
.App {
  background-color: #eaeaea;
  font-family: Arial, Helvetica, sans-serif;
}
.pd-20 {
  padding: 20px;
}
.pt-0 {
  padding-top: 0px;
}
.mt--30 {
  margin-top: -30px;
}
.error {
  border: 2px solid red;
}

.grid-cell-left {
  text-align: left;
}
.grid-cell-center {
  text-align: center;
}

.ml-30 {
  margin-left: 30px !important;
}
